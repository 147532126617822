module.exports = [{
      plugin: require('/Users/laurent/Documents/soluble-brand-center-server/gatsby/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-145145097-1"},
    },{
      plugin: require('/Users/laurent/Documents/soluble-brand-center-server/gatsby/node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.1},
    },{
      plugin: require('/Users/laurent/Documents/soluble-brand-center-server/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
